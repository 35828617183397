import TranslationID from "data/locales/id/business.json";
import { ContainerDesktop } from "components/Container";
import { IconBooks } from "components/Icon/IconBooks";
import { IconElectric } from "components/Icon/IconElectric";
import { IconCurrency } from "components/Icon/IconCurrency";
import Button from "components/Button";
import { useRouter } from "next/router";
import { pushDataLayer } from "lib/gtag";
import { usePopupStoreFormSales } from "modules/Home/popupStore";
import { useEffect, useState, useCallback } from "react";
import Image from "next/image";

export const HomeBenefit: React.FC = () => {
  const headingSectionBenefit = TranslationID["HomeBenefit"];
  const router = useRouter();
  const SLIDE_DURATION = 7000; // Duration for each slide in milliseconds

  const { showPopupFormSales } = usePopupStoreFormSales((state) => ({
    showPopupFormSales: state.showPopupFormSales,
  }));

  const [activeIndex, setActiveIndex] = useState(0);
  const [previousIndex, setPreviousIndex] = useState(0);
  const [progress, setProgress] = useState<number[]>(() =>
    Array(headingSectionBenefit.content.length).fill(0)
  );
  const [isTransitioning, setIsTransitioning] = useState(false);

  // Handle slide transitions
  useEffect(() => {
    const slideInterval = setInterval(() => {
      setIsTransitioning(true);
      setPreviousIndex(activeIndex);
      setActiveIndex((prevIndex) => {
        const nextIndex =
          prevIndex === headingSectionBenefit.content.length - 1
            ? 0
            : prevIndex + 1;
        return nextIndex;
      });

      // Reset transition state after animation completes
      setTimeout(() => {
        setIsTransitioning(false);
      }, 500); // Match this with CSS transition duration
    }, SLIDE_DURATION);

    return () => clearInterval(slideInterval);
  }, [headingSectionBenefit.content.length, activeIndex]);

  // Handle progress bar animation
  useEffect(() => {
    const startTime = Date.now();

    const progressInterval = setInterval(() => {
      const elapsedTime = Date.now() - startTime;
      const progressValue = Math.min((elapsedTime / SLIDE_DURATION) * 100, 100);

      setProgress((prev) =>
        prev.map((_, idx) => (idx === activeIndex ? progressValue : 0))
      );

      if (progressValue >= 100) {
        clearInterval(progressInterval);
      }
    }, 16);

    return () => clearInterval(progressInterval);
  }, [activeIndex]);

  const getProgressBarStyles = (index: number) => {
    if (isTransitioning) {
      if (index === previousIndex) {
        return {
          transform: "translateX(100%)",
          opacity: 0,
          transition: "transform 500ms ease-in-out, opacity 500ms ease-in-out",
        };
      } else if (index === activeIndex) {
        return {
          transform: "translateX(0)",
          opacity: 1,
          transition: "transform 500ms ease-in-out, opacity 500ms ease-in-out",
        };
      }
    }
    return {
      transform: "translateX(0)",
      opacity: index === activeIndex ? 1 : 0.3,
    };
  };

  const handleScroll = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    // first prevent the default behavior
    e.preventDefault();
    // get the href and remove everything before the hash (#)
    const href = e.currentTarget.href;
    const targetId = href.replace(/.*\#/, "");
    // get the element by id and use scrollIntoView
    const elem = document.getElementById(targetId);
    elem?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  };

  const renderDescription = (
    contents: any,
    isImageLeft: boolean,
    isTwoColumn: boolean
  ) => {
    let buttonItem = <div></div>;
    let secondButtonItem = <div></div>;

    if (contents.icon === "book") {
      buttonItem = (
        <Button
          className="!text-b1 hg-max:!text-b2 !h-40 px-80 mt-12"
          variant="secondary_transparant"
          onClick={() => {
            pushDataLayer({ event: "benefit-install" });
            showPopupFormSales("");
          }}
        >
          {contents.buttonDesc}
        </Button>
      );
    } else if (contents.icon === "electric") {
      buttonItem = (
        <Button
          className="!text-b1 hg-max:!text-b2 !h-40"
          variant="secondary_transparant"
          onClick={() => {
            pushDataLayer({ event: "benefit-report" });
            showPopupFormSales("");
          }}
        >
          {contents.buttonDesc}
        </Button>
      );
    } else if (contents.icon === "currency") {
      buttonItem = (
        <a href="#home-packets" onClick={handleScroll} className="!w-full !h-40 lg:!w-auto lg:flex lg:items-center lg:justify-start">
          <Button
            variant="secondary_transparant"
            className="!h-full !text-b1 hg-max:!text-b2"
            isFull
          >
            {contents.buttonDesc}
          </Button>
        </a>
      );
    } else if (Array.isArray(contents)) {
      buttonItem = (
        <Button
          className="!text-b1 hg-max:!text-b2 !h-40"
          variant="secondary_transparant"
          onClick={(e) => {
            e.preventDefault();
            pushDataLayer({ event: "benefit-report" });
            showPopupFormSales("");
          }}
        >
          {contents[0].buttonDesc}
        </Button>
      );

      secondButtonItem = (
        <Button
          className="!text-b1 hg-max:!text-b2 !h-40"
          variant="secondary_transparant"
          onClick={() => {
            pushDataLayer({ event: "benefit-report" });
            showPopupFormSales("");
          }}
        >
          {contents[1].buttonDesc}
        </Button>
      );
    }

    const [activeImageIndex, setActiveImageIndex] = useState(0);
    const [direction, setDirection] = useState("right");
    const CAROUSEL_INTERVAL = 3000;

    useEffect(() => {
      const carouselInterval = setInterval(() => {
        if (contents?.icon === "electric") {
          setActiveImageIndex((prevIndex) => {
            if (direction === "right") {
              if (prevIndex === 2) {
                setDirection("left");
                return 1;
              }
              return prevIndex + 1;
            } else {
              if (prevIndex === 0) {
                setDirection("right");
                return 1;
              }
              return prevIndex - 1;
            }
          });
        }
      }, CAROUSEL_INTERVAL);

      return () => clearInterval(carouselInterval);
    }, [contents, direction]);

    const [showSecondImage, setShowSecondImage] = useState(false);

    useEffect(() => {
      if (
        contents.icon === "currency" &&
        activeIndex === headingSectionBenefit.content.indexOf(contents)
      ) {
        const timer = setTimeout(() => {
          setShowSecondImage(true);
        }, 1000);

        return () => clearTimeout(timer);
      } else {
        setShowSecondImage(false);
      }
    }, [contents, activeIndex]);

    return (
      <div className="grid grid-cols-[2fr_3fr] gap-16 p-24 rounded-12 lg-max:grid-cols-1">
        <div
          key={contents.title + Math.random()}
          className="w-full h-full flex flex-col gap-16 justify-center items-start rounded-16 bg-chip-pattern pt-24 lg:pt-0 lg:p-24"
        >
          <div className="flex items-center gap-12 lg:gap-24">
            <div className="w-48">
              <div className="bg-purple-60 w-48 h-48 flex justify-center rounded-full items-center">
                {
                  {
                    book: <IconBooks />,
                    electric: <IconElectric />,
                    currency: <IconCurrency />,
                  }[contents.icon]
                }
              </div>
            </div>
            <h3 className="w-full text-start text-purple-50 lg:text-h3 font-bold text-b1 py-4">
              {contents.title}
            </h3>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: contents.description,
            }}
            className="text-start text-neutral-80 text-b1 font-light sm-max:text-b2"></div>

          {buttonItem}
        </div>

        <div
          className={`w-full flex relative lg-max:order-first py-40 ${contents.icon === "currency" ? "pl-40" : "px-40"
            } mr-40 rounded-3xl bg-purple-50`}
        >
          {contents.icon == "book" ? (
            <video
              className="rounded-lg lg:rounded-3xl w-full h-full object-cover border-2 lg:border-8 border-neutral-80 pointer-events-none"
              muted
              autoPlay
              loop
              playsInline
              disableRemotePlayback
              src={contents.imageUrl}
              ref={(video) => {
                if (video) {
                  video.addEventListener('touchstart', (e) => e.preventDefault(), { passive: false });
                }
              }}

            />
          ) : contents.icon == "electric" ? (
            <div className="relative overflow-hidden">
              <div
                className="flex transition-transform duration-500 ease-in-out h-full"
                style={{
                  transform: `translateX(-${activeImageIndex * 100}%)`,
                  width: "300%",
                }}
              >
                {[
                  contents.imageUrl[0],
                  contents.imageUrl[1],
                  contents.imageUrl[2],
                ].map((image, index) => (
                  <div key={index} className="w-full flex-shrink-0">
                    <img
                      className="w-[33%] object-cover"
                      src={image}
                      alt={`${contents.imageAlt} ${index + 1}`}
                    />
                  </div>
                ))}

              </div>
            </div>
          ) : (
            <div className="relative overflow-hidden">
              <div className="w-[100%] flex gap-12">
                <img
                  className="w-[27%]"
                  src={contents.imageUrl[0]}
                  alt={contents.imageAlt}
                />

                <img
                  className={`w-[100%] transition-transform duration-1000 ease-in-out`}
                  style={{
                    transform: `translateX(${showSecondImage ? "0" : "100%"})`,
                  }}
                  src={contents.imageUrl[1]}
                  alt={contents.imageAlt}
                />
              </div>
            </div>

          )}
        </div>
      </div>
    );
  };

  return (
    <section id="home-benefit">
      <div className="h-52"></div>
      <div className="gap-24 justify-center text-center py-30 rounded-xl bg-total-pattern">
        <div className="grid gap-8">
          <h2 className="text-h3 font-black text-neutral-80 sm-max:text-h4">
            {headingSectionBenefit.title}
          </h2>

          <div className="text-h4 font-light text-neutral-70 sm-max:text-b1">
            {headingSectionBenefit.description}
          </div>
        </div>

        <div className="flex gap-12 justify-between items-center mb-4 p-24 mx-40">
          {headingSectionBenefit.content.map((_, index) => (
            <div key={index} className="flex-1">
              <div className="relative h-4 bg-purple-10 rounded-full overflow-hidden">
                <div
                  className="h-full bg-purple-50 absolute inset-0"
                  style={{
                    width: `${progress[index]}%`,
                    ...getProgressBarStyles(index),
                  }}
                />
              </div>
            </div>
          ))}
        </div>

        <div className="relative overflow-hidden h-auto w-full">
          {headingSectionBenefit.content.map((item: any, index: number) => {
            return (
              <div
                key={index}

                className={`${index === activeIndex
                  ? "translate-y-0 opacity-100 relative"
                  : "translate-y-full opacity-0 absolute"
                  } inset-0 transition-transform duration-700 ease-in-out`}

              >
                {renderDescription(item, false, false)}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

import { ReactNode } from "react";

interface Item {
  label?: string;
  value?: string;
  selected?: boolean;
  child?: ReactNode;
}

export const Switch: React.FC<{
  data?: Item[];
  onSelect?: (a?: string) => void;
}> = ({ data, onSelect }) => {
  return (
    <div className="bg-neutral-10 rounded-40 p-4 flex w-fit">
      {data && data.length
        ? data.map((a, i) => (
            <div
              className={`py-8 cursor-pointer px-12 flex justify-center items-center gap-4 text-neutral-60 ${
                a.selected ? "bg-purple-50 rounded-40 !text-white" : ""
              }`}
              onClick={() => onSelect(a.value)}
              onMouseUp={() => onSelect(a.value)}
              onMouseUpCapture={() => onSelect(a.value)}
              key={a.value + Math.random()}
            >
              {a.label}

              {a.child ? <div className="flex">{a.child}</div> : null}
            </div>
          ))
        : null}
    </div>
  );
};

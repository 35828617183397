import TranslationID from "data/locales/id/business.json";
import { ContainerDesktop } from "components/Container";
import ButtonScheduleDemo from "components/Button/ButtonScheduleDemo";
import ButtonContact from "components/Button/ButtonContact";
import { Logo, LogoBusiness } from "components/Logo";

export const HomeContacts: React.FC = () => {
  const headingSectionContact = TranslationID["HomeContact"];

  return (
    <section id="home-contact">
      <ContainerDesktop className="pb-52" useAuto>
        <div className="grid gap-24 text-center shadow-chip_total rounded-16 p-24">
          <div className="grid gap-12">
            <h2
              dangerouslySetInnerHTML={{
                __html: headingSectionContact.title,
              }}
              className="text-t1 font-bold text-neutral-80 lg-max:text-h3"
            ></h2>

            <h3 className="text-h4 font-light text-neutral-70 lg-max:text-b1 md:px-240">
              {headingSectionContact.description}
            </h3>
          </div>

          <div className="flex justify-center items-center">
            {/* <ButtonScheduleDemo className="!h-48" id="cta-bottom" /> */}
            <ButtonContact
              className=" !h-48 w-full lg:w-[400px]"
              id="cta-bottom"
              inputStep={1}
            />
          </div>
        </div>
      </ContainerDesktop>
    </section>
  );
};

import TranslationID from "data/locales/id/business.json";
import { ContainerDesktop } from "components/Container";
import { useEffect, useState } from "react";
import { TabsCustom } from "components/Tabs/TabsCustom";
import { SearchResponse } from "@algolia/client-search/dist/client-search";
import { CourseHit } from "lib/algolia";
import { Course } from "components/Card/interfaces";
import { MoonLoader } from "react-spinners";
import { useIsDesktop } from "lib/device";
import Link from "next/link";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Keyboard, Mousewheel, Navigation, Pagination } from "swiper";
import CardCourseWithLesson from "components/Card/CardCourseWithLesson";
import { usePopupStoreFormSales } from "modules/Home/popupStore";
import { pushDataLayer } from "lib/gtag";
import Button from "components/Button";

SwiperCore.use([Navigation]);

export const HomeAccess: React.FC = () => {
  const headingSectionAccess = TranslationID["HomeAccess"];
  const [selectedItem, setSelectedItem] = useState("");
  const [allCategories, setAllCategories] = useState([]);
  const [allCourse, setAllCourse] = useState<SearchResponse<CourseHit> | null>(
    null
  );
  const [isLoading, setLoading] = useState(false);
  const [allData, setAllData] = useState([]);

  const { showPopupFormSales } = usePopupStoreFormSales((state) => ({
    showPopupFormSales: state.showPopupFormSales,
  }));

  useEffect(() => {
    getCoursesByCategory();
  }, []);

  const onSelectItem = (item: any) => {
    setSelectedItem(item.name);
  };

  const onClickSeeMoreCourses = () => {
    pushDataLayer({ event: "course-access-home-see-more" });
    showPopupFormSales("");
  };

  const getCoursesByCategory = () => {
    fetch("/api/home")
      .then((res) => res.json())
      .then((data) => {
        const category =
          data.data?.hits && data.data?.hits.length
            ? data.data.hits.map((a, idx) => {
                return {
                  name: a.name,
                  id: idx + 1,
                };
              })
            : [];

        // const filteredCategories = category.filter((item) =>
        //   [1, 2, 3, 6].includes(item.id)
        // );

        setAllCategories(category);

        setSelectedItem(category[0]?.name);
        setAllData(data.data?.hits);
      });
  };

  const filterByKey = (key: string) => {
    if (key) {
      setLoading(true);
      const courses = [...allData].find((s) => s.name === key)?.data;
      const all = courses.length
        ? courses.map((a: Course, index) => {
            return {
              ...a,
              skills:
                a.skills.length > 0 &&
                a.skills.some((a) => typeof a == "string")
                  ? a.skills.map((a) => {
                      return { name: a, slug: a };
                    })
                  : a.skills,
            };
          })
        : [];
      setLoading(false);
      setAllCourse(all);
    }
  };

  const [animationClass, setAnimationClass] = useState("");

  useEffect(() => {
    if (selectedItem) {
      setAnimationClass("slide-out");

      const slideOutTimer = setTimeout(() => {
        filterByKey(selectedItem);
        setAnimationClass("slide-in");
      }, 500);

      const slideInTimer = setTimeout(() => setAnimationClass(""), 1000);

      return () => {
        clearTimeout(slideOutTimer);
        clearTimeout(slideInTimer);
      };
    }
  }, [selectedItem]);

  const propsTemplate = {
    title: {
      label: null,
      image_url: null,
    },
    cardId: "Card-Course-Business",
    isLoading: false,
    error: null,
    trackId: "homepage_business",
  };

  const settings = {
    className: "swiper-customized-business",
    freeMode: true,
    navigation: true,
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 10,
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 10,
      },
      // when window width is >= 912px
      912: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 10,
      },
      // when window width is >= 1200px
      1200: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 20,
      },
    },
  };

  useEffect(() => {
    if (selectedItem) {
      filterByKey(selectedItem);
    }
  }, [selectedItem]);

  const isDesktop = useIsDesktop();

  // Data doesn't returned from API
  if (
    !isLoading &&
    (allCourse?.length == 0 || allCourse?.length == undefined)
  ) {
    return <></>;
  }
  const isMobileView =
    typeof window !== "undefined" && window.screen.width < 993;

  return (
    <section id="home-access">
      <div className="h-40"></div>
      <ContainerDesktop className="py-52 sm-max:py-36" useAuto>
        <div className="flex flex-col gap-24 justify-center text-center">
          <div className="grid gap-8">
            <h2 className="text-h3 font-black text-neutral-80 sm-max:text-h4">
              {headingSectionAccess.title}
            </h2>

            <h3 className="text-h4 font-light text-neutral-70 md:px-240 sm-max:text-b1">
              {headingSectionAccess.description}
            </h3>
          </div>

          <div className="flex flex-col gap-24 w-full mt-24">
            <div
              className={`${
                !isMobileView && "hide-scrollbar"
              } overflow-x-scroll flex justify-between gap-8`}
            >
              <TabsCustom
                data={allCategories}
                selectedItem={selectedItem}
                onSelectItem={onSelectItem}
                useMinWidth
              />
            </div>

            <div className="w-full overflow-hidden relative">
              {isLoading ? (
                <div className="flex w-full p-24 items-center justify-center">
                  <MoonLoader />
                </div>
              ) : allCourse && allCourse.length > 0 ? (
                <div className={animationClass}>
                  <Swiper 
                  {...settings}
                    cssMode={true}
                    mousewheel={true}
                    keyboard={true}
                    modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                  >
                    {/* @ts-ignore */}
                    {allCourse.map((course, index) => (
                      <SwiperSlide key={"SwiperSlide" + index}>
                        <CardCourseWithLesson
                          {...{ ...course, index }}
                          className="card-default"
                          isLayoutNew={true}
                          isHorizontal
                          onClickFunction={() => {
                            pushDataLayer({ event: "course-access-home" });
                            showPopupFormSales("");
                          }}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </ContainerDesktop>
    </section>
  );
};

import TranslationID from "data/locales/id/business.json";
import { ContainerDesktop } from "components/Container";
import { Image } from "components/Image/Image";
import { pushDataLayer } from "lib/gtag";
import { usePopupStoreFormSales } from "modules/Home/popupStore";
import Button from "components/Button";
import { DataInfo } from "./HomeInformation";
import { useEffect, useState } from "react";

export const UserCompanies = [
  {
    name: "kemenperin",
    image_url:
      "https://staging-static.gokampus.com/course-institutions/PgSTbEwHeIaow3LoiMWJL9MCeEowfAqZbRMNFE2X.png",
  },
  {
    name: "pan-rb",
    image_url: "https://www.menpan.go.id/site/images/logo_new.png",
  },
  {
    name: "kapal-api",
    image_url:
      "https://s.gokampus.com/tenants/h7tBkxynkbFqEEsqmnlBIWLYqs9818UtqOP4TZzz.png",
  },
  {
    name: "bluebird",
    image_url:
      "https://s.gokampus.com/tenants/7p5nbeVAVOm0qMHWUU5UCafQ6vVp1E7sDS8SqRLG.png",
  },
  {
    name: "darya-varia",
    image_url: "https://s.gokampus.com/business/logo-darya-varia.png",
  },
  {
    name: "aquaproof",
    image_url: "https://s.gokampus.com/business/logo-aquaproof.png",
  },
  {
    name: "wonderful-indonesia",
    image_url: "https://s.gokampus.com/business/logo-wonderful-indonesia.png",
  },
  {
    name: "mega-insurance",
    image_url: "https://s.gokampus.com/business/logo-mega-insurance.png",
  },
  {
    name: "boga",
    image_url: "https://s.gokampus.com/business/logo-boga-red.png",
  },
  {
    name: "zap",
    image_url:
      "https://staging-static.gokampus.com/tenants/zVnouvLjX8dYIo3SeFsveyPrtgKtPjvvJ3bWjIIg.jpeg",
  },
  {
    name: "summarecon-agung",
    image_url: "https://s.gokampus.com/business/logo-summarecon-agung.png",
  },
  {
    name: "indosat-digital-ecosystem",
    image_url:
      "https://staging-static.gokampus.com/course-institutions/qxPa26tOotbk4UDG3tiKhDjWrd9wv7XCPPXZ2S0E.png",
  },
  {
    name: "ppsdm-aparatur-esdm",
    image_url:
      "https://s.gokampus.com/tenants/WlnVzEy7CcF5VWTrd0IZIi1LDYBY4glIMr9WG71j.jpeg",
  },
  {
    name: "sucor-sekuritas",
    image_url:
      "https://staging-static.gokampus.com/course-institutions/6FDrcHmhLJzGSi4cqk2YMsDMHhwbFgBF0xSl1lxl.svg",
  },
  {
    name: "arwana-ceramics",
    image_url: "https://s.gokampus.com/business/logo-arwana-ceramics.png",
  },
  {
    name: "tdr",
    image_url: "https://s.gokampus.com/business/logo-tdr.png",
  },
  {
    name: "blibli",
    image_url:
      "https://s.gokampus.com/tenants/eNiPV814LTbT43yfGqQrMfYWUBfKUdVRTe2w2ZHx.png",
  },
  {
    name: "emtek",
    image_url: "https://s.gokampus.com/business/logo-emtek.png",
  },
];

const DoubledUserCompanies = [...UserCompanies, ...UserCompanies];

export const HomeCompanies: React.FC = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const textCompanies = TranslationID["HomeCompanies"];

  const { showPopupFormSales } = usePopupStoreFormSales((state) => ({
    showPopupFormSales: state.showPopupFormSales,
  }));

  useEffect(() => {
    const scrollSpeed = 1;
    const scrollInterval = 30;

    const interval = setInterval(() => {
      setScrollPosition((prevPosition) => {
        const newPosition = prevPosition + scrollSpeed;
        const containerWidth = UserCompanies.length * 200;
        return newPosition >= containerWidth ? 0 : newPosition;
      });
    }, scrollInterval);

    return () => clearInterval(interval);
  }, []);

  const onClickImages = () => {
    pushDataLayer({
      event: "cta-client",
    });
    showPopupFormSales("");
  };

  const onClickSeeMore = () => {
    pushDataLayer({
      event: "cta-client-see-more",
    });
    showPopupFormSales("");
  };

  return (
    <section id="home-companies">
      <ContainerDesktop className="py-52 sm-max:py-36" useAuto>
        <div className="flex flex-col gap-36 text-center">
          <h3
            className="text-b2 font-medium lg:text-h3 lg:font-black text-neutral-80 lg-max:text-h4"
            dangerouslySetInnerHTML={{
              __html: DataInfo.industry + " " + textCompanies.title,
            }}
          />

          <div
            className="relative overflow-hidden cursor-pointer shadow-chip_total py-20 bg-white rounded-16"
            onClick={onClickImages}
          >
            <div
              className="flex whitespace-nowrap transition-transform duration-100"
              style={{
                transform: `translateX(-${scrollPosition}px)`,
              }}
            >
              {DoubledUserCompanies.map((institution, index) => (
                <div
                  key={`${institution.name}-${index}`}
                  className="inline-flex aspect-[2/1] items-center justify-center bg-white px-16 py-16"
                  style={{ minWidth: "200px" }}
                >
                  <Image
                    src={institution.image_url}
                    alt={institution.name}
                    className="w-full h-full object-contain"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </ContainerDesktop>
    </section>
  );
};

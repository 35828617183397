import TranslationID from "data/locales/id/business.json";
import { ContainerDesktop } from "components/Container";
import { SwiperSlide, Swiper } from "swiper/react";
import { Image } from "components/Image/Image";
import { pushDataLayer } from "lib/gtag";
import { usePopupStoreFormSales } from "modules/Home/popupStore";
import SwiperCore, { Keyboard, Mousewheel, Navigation, Pagination } from "swiper";
import { Divider } from "components/Divider/Divider";
import { delay } from "framer-motion";

SwiperCore.use([Pagination]);

export const HomeTestimony: React.FC = () => {
  const wordingTestimony = TranslationID["HomeTestimony"];

  const settings = {
    slidesPerView: 1,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
    allowTouchMove: false,
    loop: true,
    speed: 2000,
    pagination: true,
  };

  const settingsSwiperCard = {
    spaceBetween: 20,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
    speed: 2000,
    loop: true,
  };

  const { showPopupFormSales } = usePopupStoreFormSales((state) => ({
    showPopupFormSales: state.showPopupFormSales,
  }));

  const onClickItem = () => {
    pushDataLayer({ event: "cta-testimony" });
    showPopupFormSales("");
  };

  return (
    <section id="home-testimony">
      {/* Card Desktop */}
      <div className="sg-max:hidden">
        <ContainerDesktop className="py-52 relative w-[70vw]" useAuto>
          <Swiper
            className="swiper-customized w-full"
            navigation
            {...settingsSwiperCard}
          >
            {wordingTestimony.testimonies.map((testimony, i) => (
              <SwiperSlide key={testimony.name + i} onClick={onClickItem}>
                <div className="grid gap-20 p-24 bg-testimony bg-white rounded-12 shadow-chip_total">
                  <div className="grid grid-cols-[1fr,7fr,2fr] sg-max:place-items-center sg-max:grid-cols-1 gap-20">
                    <Image
                      src={testimony.urlPeople}
                      alt={testimony.name}
                      className="w-[72px] object-contain rounded-16"
                    />
                    <div className="grid sg-max:text-center">
                      <p className="text-h4"> {testimony.name}</p>
                      <p className="text-b1 font-light">{testimony.job}</p>
                    </div>

                    <div className="flex justify-end">
                      <Image
                        src={testimony.urlInstitution}
                        alt={testimony.title}
                        /// Since migrating to AWS, every "height"/"width" attribute
                        /// need to convert to className as "max-h"/"max-w" attribute
                        // height={70}
                        className="object-contain max-h-[70px]"
                      />
                    </div>
                  </div>
                  <Divider className="hidden sg-max:block border-neutral20_to_dark30" />
                  <p className="text-h4 font-light ">"{testimony.body}"</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </ContainerDesktop>
      </div>

      {/* Card Mobile */}
      <div className="hidden sg-max:block">
        <ContainerDesktop className="py-52 relative">
          <Swiper
            cssMode={true}
            mousewheel={true}
            keyboard={true}
            pagination={{
              clickable: true,
            }}
            modules={[Navigation, Pagination, Mousewheel, Keyboard]}
            className="mySwiper swiper-customized w-full"
            {...settingsSwiperCard}
          >
            {wordingTestimony.testimonies.map((testimony, i) => (
              <SwiperSlide key={testimony.name + i} onClick={onClickItem}>
                <div className="grid gap-16 p-24 bg-total-pattern rounded-12">
                  <div className="flex justify-between">
                    <Image
                      src={testimony.urlPeople}
                      alt={testimony.name}
                      className="w-[72px] object-contain rounded-16"
                    />
                    <Image
                      src={testimony.urlInstitution}
                      alt={testimony.title}
                      /// Since migrating to AWS, every "height"/"width" attribute
                      /// need to convert to className as "max-h"/"max-w" attribute
                      // height={70}
                      className="object-contain max-h-[72px]"
                    />
                  </div>
                  <div className="grid">
                    <p className="text-b1"> {testimony.name}</p>
                    <p className="text-b2 font-light">{testimony.job}</p>
                  </div>
                  <Divider className="hidden sg-max:block border-neutral20_to_dark30" />
                  <p className="text-b1 font-light">"{testimony.body}"</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </ContainerDesktop>
      </div>
    </section>
  );
};

import TranslationID from "data/locales/id/business.json";
import { ContainerDesktop } from "components/Container";
import ButtonContact from "components/Button/ButtonContact";
import ImageAutoplay from "components/Autoplay/ImageAutoplay";
import Link from "next/link";
import { usePopupStoreFormSales } from "modules/Home/popupStore";
import { DataInfo } from "./HomeInformation";
import { pushDataLayer } from "lib/gtag";
import Images from "next/image";
import { useEffect, useRef, useState } from "react";
import { set } from "video.js/dist/types/tech/middleware";
import ReactPlayer from "react-player";
import { MediaPlayer } from "@vidstack/react/types/vidstack";

export const HomeDescription: React.FC = () => {
  const headingSectionDescription = TranslationID["HomeDescription"];
  const headingTranslationButton = TranslationID["Button"];
  const { showPopupYoutube } = usePopupStoreFormSales((state) => ({
    showPopupYoutube: state.showPopupYoutube,
  }));

  const onClickVideo = () => {
    pushDataLayer({ event: "cta-video" });
    showPopupYoutube();
  };

  const handleScroll = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    // first prevent the default behavior
    e.preventDefault();
    // get the href and remove everything before the hash (#)
    const href = e.currentTarget.href;
    const targetId = href.replace(/.*\#/, "");
    // get the element by id and use scrollIntoView
    const elem = document.getElementById(targetId);
    elem?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  };

  const renderBusinessChip = (className, title, description) => {
    return (
      <div
        className={`bg-total-pattern rounded-12 shadow-chip_total border border-white p-8 flex flex-col items-center justify-center h-fit ${className}`}
      >
        <div className="font-black text-h4 text-purple-50">{title}</div>
        <div className="font-light text-b1 2xl-max:text-b2">{description}</div>
      </div>
    );
  };

  const [isDesktop, setIsDesktop] = useState(null);

  const videoRef = useRef(null);
  const containerRefDesktop = useRef(null);
  const containerRefMobile = useRef(null);
  const progressBarRef = useRef(null);

  const [activeSection, setActiveSection] = useState(1);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(true);
  const [isBuffering, setIsBuffering] = useState(true);
  const [percentage, setPercentage] = useState(0);

  const sections = [
    {
      id: 1,
      start: 0,
      end: 123,
      no: "1",
      title: "Feedback Cerdas dari AI",
      description:
        "Dapatkan umpan balik khusus dari AI yang dirancang untuk membantu Anda mengasah kompetensi dan meningkatkan performa kerja.",
    },
    {
      id: 2,
      start: 123,
      end: 153,
      no: "2",
      title: "Diskusi Profesional yang Terarah",
      description:
        "AI menjaga percakapan tetap fokus pada topik profesional. Jika ada pertanyaan di luar konteks pekerjaan, AI akan mengarahkan kembali ke topik yang relevan.",
    },
    {
      id: 3,
      start: 153,
      end: 213,
      no: "3",
      title: "Tingkatkan Kinerja dengan AI",
      description:
        "Optimalkan performa Anda dengan wawasan AI untuk mendorong produktivitas dan hasil kerja yang maksimal.",
    },
  ];

  const handlePlayVideo = () => {
    setIsPlaying(true);
    setTimeout(() => {
      setIsMuted(false);
    }, 500);
  };

  const handlePauseVideo = () => {
    setIsMuted(true);
    setIsPlaying(false);
  };

  const handleBuffering = () => {
    setIsBuffering(true);
  };

  const handleBufferingEnd = () => {
    setIsBuffering(false);
  };

  const handleVideoReady = () => {
    handleBufferingEnd();
    handlePlayVideo();

    setTimeout(() => {
      handleBuffering();
      handlePauseVideo();
    }, 500);

    setTimeout(() => {
      handleBufferingEnd();
      handlePlayVideo();
    }, 1000);
  };

  useEffect(() => {
    const video = videoRef.current;
    setIsPlaying(false);
    setIsMuted(true);

    if (video) {
      const section = sections.find((s) => s.id === activeSection);
      if (section) {
        video.seekTo(section.start, "seconds");
        setIsPlaying(true);
        setTimeout(() => {
          setIsMuted(false);
        }, 500);
      }
    }

    return () => {
      setIsPlaying(false);
      setIsMuted(true);
    };
  }, [activeSection]);

  const handleProgress = (state: { played: number; }) => {
    const progress = state.played * 100;
    setPercentage(progress);

    const currentTime = state.played * sections[sections.length - 1].end;
    sections.forEach((section) => {
      if (currentTime >= section.start && currentTime < section.end) {
        if (activeSection !== section.id) {
          setActiveSection(section.id);
        }
      }
    });
  };

  const observeVisibility = (ref) => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsPlaying(true);
          setTimeout(() => {
            setIsMuted(false);
          }, 500);
        } else {
          setIsPlaying(false);
          setIsMuted(true);
        }
      },
      { threshold: 0.1 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  };

  const player = (
    <ReactPlayer
      ref={videoRef}
      url={"https://s.gokampus.com/vid/goKampus+AI+Advisor+-+Demo.mp4"}
      controls={false}
      playing={isPlaying}
      onPause={handlePauseVideo}
      onPlay={handlePlayVideo}
      onBuffer={handleBuffering}
      onBufferEnd={handleBufferingEnd}
      onReady={handleVideoReady}
      onProgress={handleProgress}
      muted={isMuted}
      // muted={true}
      loop={true}
      progressInterval={100}
      playsinline={true}
      width="100%"
      height="100%"
    />
  );

  useEffect(() => {
    const unobserveDesktop = observeVisibility(containerRefDesktop);
    const unobserveMobile = observeVisibility(containerRefMobile);

    const resizeHandler = () => {
      if (window.innerWidth >= 1024) {
        setIsDesktop(true);
      } else if (window.innerWidth < 1024) {
        setIsDesktop(false);
      }
    };

    resizeHandler();
    window.addEventListener("resize", resizeHandler);

    return () => {
      unobserveDesktop();
      unobserveMobile();
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  return (
    <section id="home-description">
      <ContainerDesktop className="pt-60" useAuto>
        <div className="grid grid-cols-1 gap-x-[42px] xl-max:grid-cols-1">
          <div className="flex flex-col gap-20 text-left items-center mb-28 md:px-200">
            <h1
              dangerouslySetInnerHTML={{
                __html: headingSectionDescription.title,
              }}
              className="font-black text-center text-h3 lg:text-h2"
            ></h1>

            <Link
              //@ts-ignore
              href={"#home-packets"}
              onClick={handleScroll}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: headingSectionDescription.description,
                }}
                className="font-light text-b1 lg:text-h4 text-center"
              ></div>
            </Link>

            <div className="flex justify-start items-center md:gap-8 sm-max:flex-col sm-max:items-start hidden lg:block">
              <ButtonContact
                className=" !h-48 w-full"

                id="cta-top"
                inputStep={1}
              />
            </div>

            <div className="w-full px-[4px] lg:hidden">
              <ButtonContact
                className=" !h-48 w-full"
                id="cta-top"
                inputStep={1}
              />
            </div>

            <div className="flex flex-col gap-[8px] lg:flex-row lg:gap-50 justify-center items-center mt-12 mb-12">
              <div className="flex gap-[4px] lg:gap-8 justify-center items-center">
                <img
                  src="/images/star-landing.svg"
                  alt="star"
                  className="w-[16px] h-[16px] lg:w-[20px] lg:h-[20px]"
                />
                <p className="text-purple-50 text-b2 lg:text-b1 font-light">
                  Integrasi Mudah di Berbagai Platform
                </p>
              </div>

              <div className="flex gap-[4px] lg:gap-8 justify-center items-center">
                <img
                  src="/images/star-landing.svg"
                  alt="star"
                  className="w-[16px] h-[16px] lg:w-[20px] lg:h-[20px]"
                />
                <p className="text-purple-50 text-b2 lg:text-b1 font-light">
                  Satu Harga untuk Jumlah Pembelajar Tanpa Batas
                </p>
              </div>
            </div>
          </div>

          {isDesktop ? (
            <div
              ref={containerRefDesktop}
              className="bg-white rounded-xl border-4 border-white p-2 hidden lg:block"
              style={{ boxShadow: "-3px 11px 41px 0px #9F9F9F26" }}
            >
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  display: "inline-block",
                }}
              >
                <div className="w-full rounded-xl overflow-hidden">
                  {player}
                </div>

                <div
                  className="absolute top-0 left-0 w-full h-full flex justify-center items-center cursor-pointer"
                  onClick={() => setIsPlaying((prev) => !prev)}
                >
                  {isPlaying ? (
                    isBuffering ? (
                      <div className="w-44 h-44 border-4 border-purple-50 border-solid border-t-transparent rounded-full animate-spin"></div>
                    ) : ""
                  ) : (
                    <img src="/images/icon-play-grey.svg" alt="play" />
                  )}
                </div>

                <div
                  className="absolute bottom-30 w-full z-10"
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    marginTop: "20px",
                  }}
                >
                  {sections.map((section) => (
                    <div
                      key={section.id}
                      onClick={() => setActiveSection(section.id)}
                      style={{
                        padding: "10px",
                        borderRadius: "8px",
                        color: "white",
                        width: "30%",
                        textAlign: "center",
                        opacity: activeSection === section.id ? 1 : 0.6,
                        transition: "opacity 0.5s",
                        background:
                          activeSection !== section.id &&
                          "linear-gradient(111.61deg, rgba(255, 255, 255, 0.76) 15.17%, rgba(255, 255, 255, 0.64) 95.84%)",
                        backdropFilter:
                          activeSection !== section.id && "blur(13px)",
                        boxShadow:
                          activeSection !== section.id &&
                          "-3px 11px 41px 0px #9F9F9F26",
                      }}
                      className={`${activeSection === section.id && "bg-purple-50"
                        } rounded-xl cursor-pointer`}
                    >
                      <div className="flex justify-center items-center gap-8 mb-4">
                        <h4
                          className={`text-h4 font-bold ${activeSection === section.id
                            ? "text-white"
                            : "text-neutral-80"
                            }`}

                        >
                          {section.title}
                        </h4>
                        <p

                          className={`text-b2 font-medium text-black rounded-full bg-white ${activeSection === section.id
                            ? "w-20 h-20"
                            : "w-22 h-22 border border-black"
                            }`}

                        >
                          {section.no}
                        </p>
                      </div>
                      <p

                        className={`text-b1 font-light ${activeSection === section.id
                          ? "text-white"
                          : "text-neutral-80"
                          }`}
                      >
                        {section.description}
                      </p>
                    </div>
                  ))}
                </div>

                <div className="bg-purple-50 rounded-tr-xl rounded-br-xl rounded-bl-xl z-20" ref={progressBarRef}
                  style={{
                    position: "absolute",
                    bottom: "0",
                    left: "0",
                    height: "10px",
                    width: `${percentage}%`,
                    transition: "width 0.1s linear",
                  }}
                ></div>
              </div>
            </div>
          ) : (
            <div ref={containerRefMobile} className="bg-purple-50 rounded-[12px] overflow-hidden lg:hidden">
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  display: "inline-block",
                }}
              >
                {player}

                <div
                  className="absolute top-0 left-0 w-full h-full flex justify-center items-center cursor-pointer"
                  onClick={() => setIsPlaying((prev) => !prev)}
                >
                  {isPlaying ? (
                    isBuffering ? (
                      <div className="w-44 h-44 border-4 border-purple-50 border-solid border-t-transparent rounded-full animate-spin"></div>
                    ) : ""
                  ) : (
                    <img src="/images/icon-play-grey.svg" alt="play" />
                  )}
                </div>

                <div
                  className="bg-purple-50"
                  ref={progressBarRef}
                  style={{
                    position: "absolute",
                    bottom: "0",
                    left: "0",
                    height: "4px",

                    width: `${percentage}%`,
                    transition: "width 0.1s linear",
                  }}
                ></div>
              </div>

              {/* decription */}
              <div className="flex flex-col items-start p-[8px] gap-[6px]">
                <div className="flex gap-[8px]">
                  {sections.map((section) => (
                    <button
                      key={section.id}
                      onClick={() => setActiveSection(section.id)}
                      className={`flex justify-center items-center rounded-full ${activeSection === section.id ? "bg-white" : "bg-transparent"} w-[16px] h-[16px] text-c1 font-medium ${activeSection === section.id ? "text-black" : "text-white"} border border-white`}
                    >
                      {section.no}
                    </button>
                  ))}
                </div>

                <h1 className="text-b1 font-black text-white">{sections[activeSection - 1].title}</h1>
                <p className="text-b2 font-light text-white">{sections[activeSection - 1].description}</p>
              </div>
            </div>
          )}
        </div>
      </ContainerDesktop>
    </section >
  );
};
